<template>
    <div class="managsystem-content-operation-day">
        <!-- TODO 暂时屏蔽预警功能，接口尚未支持 -->
        <div class="block-box" v-if="false">
            <div class="head-box">
                <div class="title">日新增数据统计</div>
                <div class="date-filter">
                    <DatePicker type="date" :options="datePickerOptions" v-model="warningDate" placeholder="选择日期" style="width: 300px" />
                </div>
            </div>
            <div class="content-box">
                <div class="warning-box">
                    <div class="block-box">
                        <div class="item-box" v-for="(item, idx) in warningModuleList" :key="idx">
                            <div class="title">{{ item.title }}</div>
                            <div class="time">{{ dateStr }}</div>
                            <div class="value-box">
                                <span>{{ item.value || 0 }}</span>
                                <p class="unit">个</p>
                            </div>
                            <div class="trend-box">
                                <div class="trend-item">
                                    <p class="name">较1日前</p>
                                    <p class="value">{{ !item.valueDayAgo || item.valueDayAgo >= 0 ? "+" : "" }}{{ item.valueDayAgo || 0 }}项</p>
                                </div>
                                <div class="trend-item">
                                    <p class="name">较15日前</p>
                                    <p class="value">{{ !item.value15Ago || item.value15Ago >= 0 ? "+" : "" }}{{ item.value15Ago || 0 }}项</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-box">
                        <Table :tableColumn="tableColumn"></Table>
                    </div>
                </div>
            </div>
        </div>

        <div class="block-box">
            <div class="head-box">
                <div class="title">日新增数据统计</div>
                <div class="date-filter">
                    <DatePicker type="daterange" :options="datePickerOptions" v-model="date" placeholder="选择日期" style="width: 300px" />
                </div>
            </div>
            <div class="content-box">
                <div class="note-box">
                    <div class="item-box" v-for="(item, idx) in chartModuleList" :key="idx">
                        <div class="title">{{ item.title }}</div>
                        <div class="time">{{ $core.formatDate(date[1], "yyyy-MM-dd") }}</div>
                        <div class="value-box">
                            <span>{{ item.value || 0 }}</span>
                            <p class="unit">{{ item.unit }}</p>
                        </div>
                        <div class="trend-box">
                            <div class="trend-item">
                                <p class="name">较1日前</p>
                                <p class="value">{{ !item.valueDayAgo || item.valueDayAgo >= 0 ? "+" : "" }}{{ item.valueDayAgo || 0 }}{{ item.unit }}</p>
                            </div>
                            <div class="trend-item">
                                <p class="name">较15日前</p>
                                <p class="value">{{ !item.value15Ago || item.value15Ago >= 0 ? "+" : "" }}{{ item.value15Ago || 0 }}{{ item.unit }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="chart-module" v-for="(item, idx) in chartModuleList" :key="idx">
                    <div class="head-box">
                        <p class="title">每日{{ item.title }}</p>
                        <div class="date">
                            <span>{{ dateStr }}</span>
                            <!-- <div class="condition-box">
                                <RadioGroup v-model="item.dateType" @on-change="getChartData(item)">
                                    <Radio label="1">按日</Radio>
                                    <Radio label="2">按周</Radio>
                                    <Radio label="3">按月</Radio>
                                    <Radio label="4">季度</Radio>
                                </RadioGroup>
                            </div> -->
                        </div>

                        <div class="more" @click="onDisplayDetail(item)">更多详情>></div>
                    </div>
                    <div class="type-box">
                        <RadioGroup v-model="item.onlineStatus" @on-change="getChartData(item)">
                            <Radio label="">全部社区</Radio>
                            <Radio label="1">试点社区</Radio>
                            <Radio label="2">试用社区</Radio>
                        </RadioGroup>
                    </div>
                    <div class="chart-box">
                        <ElemChart :option="item.option" height="300px"></ElemChart>
                    </div>
                </div>
            </div>
        </div>

        <CompModal ref="comp_modal" :title="'各社区每日' + (detail.title || '统计')" width="80%">
            <div class="managsystem-content-operation-detail">
                <div class="community-select">
                    <CheckboxGroup v-model="detail.community" @on-change="getCommunityDetailData(detail)">
                        <Checkbox v-for="(item, idx) in communitys" :key="idx" :label="item.orgCode">
                            <span>{{ item.orgName }}</span>
                        </Checkbox>
                    </CheckboxGroup>
                </div>
                <ElemChart class="chart-box" :option="detail.lineOption"></ElemChart>
            </div>
        </CompModal>
    </div>
</template>

<script>
import CompModal from "@/views/residentdatabase/components/CompModal.vue"
import Table from "./childrens/Table.vue"
import ElemChart from "./childrens/ElemChart.vue"

import xlsx from "xlsx"
import Street from "./utils/street"
import DateUtil from "./utils/date"

export default {
    components: { CompModal, Table, ElemChart },

    data() {
        // 日期范围为 8 天前至 1 天前，今天不可选
        let date = [DateUtil.getDateByDay(-8), DateUtil.getDateByDay(-1)]

        return {
            warningDate: new Date(),
            date: date,
            dateStr: this.$core.formatDate(date[0], "yyyy-MM-dd") + " - " + this.$core.formatDate(date[1], "yyyy-MM-dd"),
            chartModuleList: [
                {
                    key: "publishNum",
                    day1: "publishAddDayAgo",
                    day15: "publishAdd15Ago",
                    title: "新增内容发布项",
                    color: "#4E74E9",
                    dateType: "1",
                    unit: "项",
                },
                {
                    key: "viewNum",
                    day1: "viewAddDayAgo",
                    day15: "viewAdd15Ago",
                    title: "居民接受服务人次",
                    color: "#EE6666",
                    dateType: "1",
                    unit: "人次",
                },
                {
                    key: "joinNum",
                    day1: "joinAddDayAgo",
                    day15: "joinAdd15Ago",
                    title: "新增居民参与和互动服务人次",
                    color: "#04B78A",
                    dateType: "1",
                    unit: "人次",
                },
            ],
            warningModuleList: [
                {
                    key: "publishNum",
                    title: "近15日内未更新内容的社区",
                    color: "#4E74E9",
                },
                {
                    key: "viewNum",
                    title: "近15日内周平均更新内容数大于1的社区",
                    color: "#EE6666",
                },
            ],
            // 日期选择器选项
            datePickerOptions: {
                disabledDate: date => {
                    // 判断日期是否大于等于今天，如果是则禁止选择
                    return date && date.valueOf() >= new Date(this.$core.formatDate(new Date(), "yyyy-MM-dd 00:00:00")).valueOf()
                },
            },
            // 社区列表
            communitys: null,
            // 表格表头配置
            tableColumn: [
                {
                    title: "预警值",
                    key: "staffName",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "类型",
                    key: "staffName",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "社区",
                    key: "staffName",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "最后一次更新内容时间",
                    key: "staffName",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "未更新内容间隔时长",
                    key: "staffName",
                    minWidth: 200,
                    align: "center",
                },
            ],
            detail: {},
        }
    },

    watch: {
        date(v) {
            if (v.length === 2) {
                this.dateStr = this.$core.formatDate(v[0], "yyyy-MM-dd") + " - " + this.$core.formatDate(v[1], "yyyy-MM-dd")
            }
            // 处理数据
            this.onProcessData()
        },
    },

    async mounted() {
        let street = await Street.getInfo()
        this.orgPath = street.orgPath
        // 处理数据
        this.onProcessData()
    },

    methods: {
        /**
         * 处理数据
         */
        async onProcessData() {
            this.getCountDate()

            for (let i = 0, l = this.chartModuleList; i < l.length; i++) {
                let v = l[i]
                await this.getChartData(v)
            }
        },

        /**
         * 获取统计数据
         */
        getCountDate() {
            this.$Message.loading({
                content: "加载中...",
                duration: 0,
            })

            const fields = []

            for (let i = 0, l = this.chartModuleList; i < l.length; i++) {
                let v = l[i]
                fields.push(v.key, v.day1, v.day15)
            }

            return this.$post(
                "/gateway/api/ck/OrgIndicator/listValueCountByCode",
                {
                    dataScopeId: this.orgPath,
                    statDate: this.$core.formatDate(this.date[1], "yyyy-MM-dd"),
                    selectedField: fields,
                },
                { "Content-Type": "application/json" }
            )
                .then(res => {
                    if (res.code == 200) {
                        let data = res.data

                        for (let i = 0, l = this.chartModuleList; i < l.length; i++) {
                            let v = l[i]
                            let val = data[v.key] || 0
                            let valDay1 = data[v.day1] || 0
                            let valDay15 = data[v.day15] || 0

                            this.$set(v, "value", val)
                            this.$set(v, "value15Ago", val - valDay15)
                            this.$set(v, "valueDayAgo", val - valDay1)
                        }
                    }
                })
                .finally(this.$Message.destroy)
        },

        getChartData(item) {
            this.$Message.loading({
                content: "加载中...",
                duration: 0,
            })

            return this.$post(
                "/gateway/api/ck/OrgIndicator/statDateUserStreet",
                {
                    dataScopeId: this.orgPath, //范围编码
                    startDate: this.$core.formatDate(this.date[0], "yyyy-MM-dd"), //开始时间
                    endDate: this.$core.formatDate(this.date[1], "yyyy-MM-dd"), //结束时间
                    dateType: item.dateType,
                    onlineStatus: item.onlineStatus,
                    selectedField: [item.key, "onlineStatus"], //查询字段
                },
                { "Content-Type": "application/json" }
            )
                .then(res => {
                    if (res.code == 200) {
                        const date = []
                        const data = []
                        const list = []
                        for (let i = 0, l = res.dataList; i < l.length; i++) {
                            let v = l[i]
                            date.push(v.statDate)
                            data.push(v[item.key])
                            list.push({
                                d: v.statDate,
                                v: v[item.key],
                            })
                        }
                        item.dataList = list
                        // 图表配置
                        this.$set(item, "option", {
                            tooltip: {
                                trigger: "axis",
                            },
                            color: item.color,
                            xAxis: {
                                type: "category",
                                boundaryGap: false,
                                data: date,
                            },
                            yAxis: {
                                type: "value",
                                scale: true,
                            },
                            series: [
                                {
                                    name: item.title,
                                    type: "line",
                                    smooth: true,
                                    data: data,
                                    markLine: {
                                        data: [
                                            { type: "average" },
                                            [
                                                {
                                                    symbol: "none",
                                                    x: "90%",
                                                    yAxis: "max",
                                                },
                                                {
                                                    symbol: "circle",
                                                    label: {
                                                        position: "start",
                                                    },
                                                    type: "max",
                                                },
                                            ],
                                        ],
                                    },
                                },
                            ],
                        })
                    } else this.$Message.error(res.desc)
                })
                .finally(this.$Message.destroy)
        },

        /**
         * 导出
         */
        onExport(item) {
            const data = []
            for (let i = 0, l = item.chartList; i < l.length; i++) {
                let v = l[i]
                data.push({
                    序号: i + 1,
                    类型: Street.getCommunityType(v),
                    社区名称: v.orgName,
                    [item.title]: v[item.id],
                })
            }
            // 合计
            data.push({
                序号: "街道小计：",
                [item.title]: item.value,
            })
            // 创建一个新 sheet
            const new_sheet = xlsx.utils.json_to_sheet(data)
            // 配置单元格宽度属性
            new_sheet["!cols"] = [{ wch: 10 }, { wch: 30 }, { wch: 20 }, { wch: 30 }]
            // 配置合并属性
            new_sheet["!merges"] = [{ s: { c: 0, r: data.length }, e: { c: 2, r: data.length } }]
            // 新建 book
            const new_book = xlsx.utils.book_new()
            // 将 sheet 添加到 book 中
            xlsx.utils.book_append_sheet(new_book, new_sheet, item.title)
            // 导出 excel 文件
            xlsx.writeFile(new_book, item.date + "-数据导出.xlsx")
        },

        async onDisplayDetail(item) {
            if (!item.detail) {
                // 拷贝数据
                item.detail = Object.assign({}, item, {
                    id: this.$core.randomString(),
                })
            }
            const detail = item.detail
            detail.date = this.date
            // 无社区列表则初始列表
            if (!this.communitys) {
                let street = await Street.getInfo()
                this.communitys = street.children
            }
            // 默认第一个社区
            detail.community = [this.communitys?.[0].orgCode]
            // 指定当前详情数据对象
            this.detail = detail
            // 获取详情数据
            await this.getCommunityDetailData(detail)
            // 显示弹窗
            this.$refs.comp_modal.display()
        },

        /**
         * 获取社区详情数据
         */
        getCommunityDetailData(item) {
            this.$Message.loading({
                content: "加载中...",
                duration: 0,
            })

            return this.$post(
                "/gateway/api/ck/OrgIndicator/statDateUserByCommunity",
                {
                    dataScopeId: this.orgPath, //范围编码
                    startDate: this.$core.formatDate(item.date[0], "yyyy-MM-dd"), //开始时间
                    endDate: this.$core.formatDate(item.date[1], "yyyy-MM-dd"), //结束时间
                    communityCode: item.community?.join(",") || "",
                    dateType: item.limit, // 1:日 2:周 3:月 4:季度
                    selectedField: ["orgCode", "orgName", "onlineStatus", "onlineType", item.key], //查询字段
                },
                { "Content-Type": "application/json" }
            )
                .then(res => {
                    if (res.code == 200) {
                        const data = res.data
                        const communitys = {}
                        const keys = []
                        const series = []

                        for (let i = 0, ks = Object.keys(data).sort((a, b) => (a > b ? 1 : -1)); i < ks.length; i++) {
                            let key = ks[i]

                            for (let idx = 0, l = data[key]; idx < l.length; idx++) {
                                let v = l[idx]

                                if (!communitys[v.orgName]) {
                                    communitys[v.orgName] = []
                                }

                                communitys[v.orgName].push(v[item.key] || 0)
                            }

                            keys.push(key)
                        }

                        for (let i = 0, ks = Object.keys(communitys); i < ks.length; i++) {
                            let key = ks[i]

                            series.push({
                                name: key,
                                type: "line",
                                smooth: true,
                                data: communitys[key],
                            })
                        }

                        const option = {
                            tooltip: {
                                trigger: "axis",
                            },
                            xAxis: {
                                type: "category",
                                boundaryGap: false,
                                data: keys,
                            },
                            yAxis: {
                                type: "value",
                                scale: true,
                            },
                            series: series,
                        }

                        item.statDateUserByCommunity = data
                        this.$set(item, "lineOption", option)

                        console.log(item)
                    }
                })
                .finally(this.$Message.destroy)
        },
    },
}
</script>

<style lang="less">
.managsystem-content-operation-day {
    background: #f8f8f8;
    position: relative;
    min-height: 100%;

    > .block-box {
        width: 100%;
        padding: 0 20px 20px;
        box-sizing: border-box;
        max-width: 1500px;
        margin: 0 auto;

        &:first-child {
            padding-top: 20px;
        }

        > .head-box {
            margin-bottom: 10px;
            display: flex;
            align-items: center;

            .title {
                font-size: 20px;
                font-weight: bold;
                color: #333;
            }

            .date-filter {
                margin: 0 20px;
            }
        }

        .content-box {
            width: 100%;

            .warning-box {
                background: #fff;
                width: 100%;
                padding: 10px;
                display: flex;
                border-radius: 6px;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);

                .block-box {
                    width: 300px;
                    flex-shrink: 0;

                    .item-box {
                        margin-bottom: 10px;
                        width: 100%;
                        padding: 10px 20px;
                        background: #eff9ff;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        box-sizing: border-box;
                        border-radius: 6px;

                        &:nth-child(1) {
                            background: #56aaff;
                        }

                        &:nth-child(2) {
                            background: #9390f3;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .title {
                            color: #333;
                            font-size: 14px;
                        }

                        .time {
                            font-size: 12px;
                            color: #666;
                        }

                        > .value-box {
                            font-size: 50px;
                            line-height: 1;
                            display: flex;
                            align-items: flex-end;
                            margin: 10px 0;
                            color: #fff;

                            .unit {
                                font-size: 14px;
                                line-height: 30px;
                                margin-left: 10px;
                            }
                        }

                        .trend-box {
                            display: flex;

                            .trend-item {
                                display: flex;
                                margin-right: 20px;

                                &:last-child {
                                    margin-right: 0;
                                }

                                .name {
                                    font-size: 14px;
                                    color: #444;
                                    margin-right: 10px;
                                }

                                .value {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }

                .table-box {
                    flex-grow: 1;
                    margin-left: 10px;
                    overflow: hidden;
                }
            }

            .note-box {
                background: #fff;
                width: 100%;
                padding: 15px;
                margin: 20px 0;
                display: flex;
                justify-content: space-between;
                border-radius: 6px;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);

                .item-box {
                    width: calc(100% / 3 - 10px);
                    margin: 10px 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    box-sizing: border-box;
                    border-right: 1px solid #f3f3f3;

                    &:last-child {
                        border-right: 0;
                    }

                    .title {
                        color: #333;
                        font-size: 14px;
                    }

                    .time {
                        font-size: 12px;
                        color: #999;
                    }

                    > .value-box {
                        font-size: 50px;
                        line-height: 1;
                        display: flex;
                        align-items: flex-end;
                        margin: 10px 0;
                        color: #57a3f3;

                        .unit {
                            font-size: 14px;
                            line-height: 30px;
                            margin-left: 10px;
                        }
                    }

                    .trend-box {
                        display: flex;

                        .trend-item {
                            display: flex;
                            margin-right: 20px;

                            &:last-child {
                                margin-right: 0;
                            }

                            .name {
                                font-size: 12px;
                                color: #444;
                                margin-right: 10px;
                            }

                            .value {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }

            .chart-module {
                margin: 10px 0;
                padding: 10px;
                background: #fff;
                border-radius: 6px;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);

                .head-box {
                    margin: 10px 10px 0 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    line-height: 1;

                    > .title {
                        font-size: 16px;
                        font-weight: bold;
                        margin-right: 20px;
                    }

                    .date {
                        flex-grow: 1;
                        color: #999;
                        display: flex;
                        align-items: center;

                        .condition-box {
                            margin-left: 20px;
                        }
                    }

                    .download-btn {
                        cursor: pointer;
                        padding: 8px 20px;
                        display: flex;
                        align-items: center;
                        background: #e3e3e3;
                        color: #000;
                        border-radius: 4px;
                        margin-right: 20px;

                        .name {
                            margin-left: 5px;
                        }
                    }

                    .more {
                        cursor: pointer;
                        font-weight: bold;
                    }
                }

                .type-box {
                    margin: 10px 10px 0 10px;
                }

                .chart-box {
                    margin-top: 10px;
                    width: 100%;
                    height: 350px;
                    display: flex;

                    .chart {
                        width: 100%;
                        height: 350px;
                    }

                    .chart-left {
                        width: 300px;
                        height: 350px;
                        flex-shrink: 0;
                    }

                    .chart-right {
                        height: 350px;
                        flex-grow: 1;
                    }
                }
            }
        }
    }
}

.managsystem-content-operation-detail {
    padding: 20px;

    .chart-box {
        margin-top: 20px;
        width: 100%;
        height: 300px;
    }
}
</style>
